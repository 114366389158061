<template>
  <main class="h-100 d-flex flex-column" :class="{loggedin: $auth.check()}" >
    <CNavbar v-if="$auth.check()" expand="lg" color-scheme="dark" class="bg-dark border-bottom-1 p-0"
             style="border-bottom: 1px solid var(--bs-dark-rgb);" placement="sticky-top ">
      <div class="container-fluid p-0">
        <router-link class="navbar-brand bg-white ps-0 ms-0" :to="{ name: 'dashboard'}">
          <img src="/img/ef_logo.svg" alt="" width="110" height="36">
        </router-link >
        <CNavbarToggler @click="visible = !visible"/>
        <CCollapse class="navbar-collapse" aria-label="Toggle navigation" aria-expanded={visible} :visible="visible">
          <CNavbarNav>
            <!--<CNavItem>
              <CNavLink href="#">Link</CNavLink>
            </CNavItem>-->
            <CDropdown variant="nav-item" :popper="false">
              <CDropdownToggle color="secondary">Rezepte</CDropdownToggle>
              <CDropdownMenu>
                <a class="dropdown-item" href="#" @click="showModalCreateRecept()"><i
                    class="fa-solid fa-file-circle-plus fa-fw"></i> neues Rezept anlegen</a>
                <a class="dropdown-item" href="#" @click="showModalSearchRecept()"><i
                    class="fa-solid fa-file-magnifying-glass fa-fw"></i> Rezept suchen</a>
                <div class="dropdown-divider"></div>
                <a v-if="$auth.user().role == 'sadmin'" class="dropdown-item" href="#" @click="showModalPublicRecept()"><i
                        class="fa-solid fa-file-magnifying-glass fa-fw"></i> Simone Modus</a>
                  <!--<router-link v-if="$auth.user().role == 'sadmin'"  class="dropdown-item" :to="{name:'publications'}"><i
                      class="fa-solid fa-globe fa-fw"></i> Veröffentlichungen</router-link>-->

                <!--<router-link class="dropdown-item" :to="{name:'dashboard'}"><i
                    class="fa-solid fa-file-magnifying-glass fa-fw"></i> Rezept suchen
                </router-link>-->
              </CDropdownMenu>
            </CDropdown>
            <CDropdown variant="nav-item" :popper="false">
              <CDropdownToggle color="secondary">Produkte</CDropdownToggle>
              <CDropdownMenu>
                <a class="dropdown-item" href="#" @click="showModalArticle()"><i class="fa-solid fa-wheat-awn fa-fw"></i> neues Produkt anlegen</a>
                <router-link class="dropdown-item" :to="{name:'products'}"><i class="fa-solid fa-magnifying-glass fa-fw"></i> Produkt suchen</router-link>
                <CDropdownDivider v-if="$auth.user().role == 'sadmin'"/>
                <router-link v-if="$auth.user().role == 'sadmin'" class="dropdown-item" :to="{name:'bls'}"><i class="fa-solid fa-magnifying-glass fa-fw"></i> BLS durchsuchen</router-link>
              </CDropdownMenu>
            </CDropdown>
          </CNavbarNav>
        </CCollapse>
        <CNavbarNav class="float-end">
          <CNavItem v-if="$auth.user().role == 'sadmin'">
            <CNavLink href="#" @click="showRememberList">
              <i class="fa-solid fa-bookmark"></i>
              Merkliste
            </CNavLink>
          </CNavItem>
          <CDropdown variant="nav-item" :popper="false">
            <CDropdownToggle color="secondary" class="text-uppercase"><i class="fa-solid fa-globe"></i> {{
                $i18n.locale
              }}
            </CDropdownToggle>
            <CDropdownMenu class="dropdown-menu-end">
              <li><a class="dropdown-item" :class="[$i18n.locale == 'de' ? 'active' : '']" href="#"
                     @click="setLocale('de')">DE</a></li>
            </CDropdownMenu>
          </CDropdown>
          <CDropdown variant="nav-item" :popper="false">
            <CDropdownToggle color="secondary"><i class="fa-solid fa-screwdriver-wrench"></i> Einstellungen</CDropdownToggle>
            <CDropdownMenu>
              <router-link  v-if="$auth.user().role == 'sadmin'" class="dropdown-item" :to="{name:'mandants'}"><i class="fa-solid fa-users fa-fw"></i> Mandaten verwalten</router-link>
              <!--<router-link  v-if="$auth.user().role == 'sadmin'" class="dropdown-item" :to="{name:'tags'}"><i class="fa-solid fa-users fa-fw"></i> Softwareoptionen</router-link>-->
              <CDropdownDivider v-if="$auth.user().role == 'sadmin'"/>
              <!--<router-link  v-if="$auth.user().role == 'sadmin'" class="dropdown-item" :to="{name:'mandants'}"><i class="fa-solid fa-user fa-fw"></i> Benutzer verwalten</router-link>-->
              <router-link  v-if="$auth.user().role == 'sadmin'" class="dropdown-item" :to="{name:'tags'}"><i class="fa-solid fa-tags fa-fw"></i> Tags verwalten</router-link>
              <CDropdownDivider v-if="$auth.user().role == 'sadmin'"/>
              <a class="dropdown-item" @click="modalPasswordChange = true"><i class="fa-solid fa-lock fa-fw"></i> Passwort ändern</a>
            </CDropdownMenu>
          </CDropdown>
          <CNavItem>
            <CNavLink href="#" @click="logout()"><i class="fa-solid fa-right-from-bracket"></i>
              {{ $t('menu.logout') }}
            </CNavLink>
          </CNavItem>
        </CNavbarNav>
      </div>
    </CNavbar>
    <router-view class="flex-grow-1" v-slot="{ Component, route }">
      <tab v-if="$auth.check()" />
      <div class="router flex-grow-1" style="overflow: auto;" v-if="route">
        <keep-alive include="PageDashboard">
          <component
              :is="Component"
          />
        </keep-alive>
      </div>
      <!--<div class="router flex-grow-1 h-100" style="overflow: auto;" v-if="(route && route.meta && !route.meta.isCache)">
        <component
            v-if="(route && route.meta && !route.meta.isCache)"
            :is="Component"
            :key="viewKey"
        />
      </div>-->
    </router-view>
    <create-modal v-model:shown="modalCreateRecept"></create-modal>
    <search-modal v-model:shown="modalSearchRecept"></search-modal>
    <public-files v-model:shown="modalPublicFiles"></public-files>
    <modal-article v-model:shown="modalArticle" :product-id="modalArticleId" :modal-options="modalArticleOption"></modal-article>

    <CModal :visible="modalPasswordChange" alignment="top" @close="modalPasswordChange=false" backdrop="static">
      <CModalHeader>
        <CModalTitle>Passwort ändern</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <div class="container-fluid">
          <div class="row">
            <div class="col">
              <div class="">
                <label for="exampleFormControlTextarea1" class="form-label">Aktuelles Passwort</label>
                <input class="form-control" type="password" id="exampleFormControlTextarea1" v-model="password.oldPwd"/>
                <ul class="error" v-if="passwordErrors && passwordErrors.oldPwd">
                  <li v-for="error in passwordErrors.oldPwd" :key="error">{{error}}</li>
                </ul>
              </div>
              <hr>
              <div class="my-3">
                <label for="exampleFormControlTextarea1" class="form-label">Neues Passwort</label>
                <input class="form-control" type="password" id="exampleFormControlTextarea1" v-model="password.pwd"/>
                <ul class="error" v-if="passwordErrors && passwordErrors.pwd">
                  <li v-for="error in passwordErrors.pwd" :key="error">{{error}}</li>
                </ul>
              </div>
              <div class="my-3">
                <label for="exampleFormControlTextarea1" class="form-label">Passwort wiederholen</label>
                <input class="form-control" type="password" id="exampleFormControlTextarea1" v-model="password.pwd2"/>
                <ul class="error" v-if="passwordErrors && passwordErrors.pwd2">
                    <li v-for="error in passwordErrors.pwd2" :key="error">{{error}}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </CModalBody>
      <CModalFooter>
        <div class="col text-end">
          <button class="btn btn-secondary btn-sm me-3" @click="modalPasswordChange=false">Abbrechen</button>
          <button class="btn btn-primary btn-sm" @click="setPassword()">Passwort ändern</button>
        </div>
      </CModalFooter>
    </CModal>

    <COffcanvas :backdrop="false" placement="bottom" :visible="showCanvasRememberList" style="z-index: 9999999">
      <COffcanvasHeader>
            <div class="col">
              <COffcanvasTitle><i class="fa-solid fa-bookmark text-tertiary"></i> Merkliste</COffcanvasTitle>
            </div>
            <div class="col text-end">
                <button class="btn btn-secondary btn-sm me-3" @click="showModalReceptBook = true" :disabled="$store.state.remember.length == 0"><i class="fa-solid fa-book"></i> Rezeptmappe erstellen</button>
                <button class="btn btn-tertiary btn-sm me-1" @click="$store.commit('clearRecepts')" :disabled="$store.state.remember.length == 0"><i class="fa-solid fa-bookmark-slash"></i> Liste leeren</button>
                  <div class="btn-group" role="group" aria-label="Basic example">
                    <select class="form-select form-select-sm" v-model="copyMandant">
                      <option v-for="n in $store.state.mandants" :value="n" :key="n">{{ n.company }}</option>
                    </select>
                    <button class="btn btn-secondary btn-sm" @click="copyToMandant()" :disabled="$store.state.remember.length == 0 || copyMandant == null"><i class="fa-solid fa-copy"></i></button>
                  </div>
            </div>
        <CCloseButton class="text-reset" @click="showCanvasRememberList=false;"/>
      </COffcanvasHeader>
      <COffcanvasBody>
        <div class="row">
          <div class="col-12 col-sm-6 col-md-3  col-xl-2 col-xxl-1 mb-3" v-for="ice in $store.state.remember" :key="ice.id">
            <single-ice-cream :ice="ice" :small="true"></single-ice-cream>
          </div>
        </div>
      </COffcanvasBody>
    </COffcanvas>
  </main>
  <modal-recept-book v-model:shown="showModalReceptBook"></modal-recept-book>
</template>

<script>
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

import axios from "axios";
import {
  CNavbar,
  CNavbarBrand,
  CNavbarToggler,
  CNavbarNav,
  CNavItem,
  CNavLink,
  CModal,
  CModalBody, CModalFooter, CModalHeader, CModalTitle
} from '@coreui/bootstrap-vue';
import {COffcanvas, COffcanvasHeader, COffcanvasTitle, CCloseButton, COffcanvasBody} from '@coreui/bootstrap-vue';
import {CForm, CFormInput, CButton} from '@coreui/bootstrap-vue';
import CreateModal from "@/pages/creator/CreateModal";
import SearchModal from "@/pages/creator/SearchModal";
import PublicFiles from "@/pages/creator/PublicFiles.vue";
import ModalArticle from "@/pages/article/ModalArticle";
import singleIceCream from "@/pages/common/SingleIceCream";

import {
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CDropdownDivider,
  CCollapse
} from '@coreui/bootstrap-vue';
import tab from "@/components/Tab";

import eventBus from "@/eventBus";
import ModalReceptBook from "@/pages/creator/modalReceptBook.vue";

export default {
  name: 'App',
  components: {
    ModalReceptBook,
    CNavbar, CNavbarBrand, CNavbarToggler, CNavbarNav, CNavItem, CNavLink,
    CDropdown, CDropdownToggle, CDropdownMenu, CDropdownDivider, CCollapse,
    CForm, CFormInput, CButton, tab,
    CreateModal, SearchModal,PublicFiles, ModalArticle,
    COffcanvas, COffcanvasHeader, COffcanvasTitle, CCloseButton, COffcanvasBody,
    singleIceCream,
    CModal, CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
  },
  data() {
    return {
      config: {
        //url: "https://tbc.dev.bitdivision.de/api/",
        url: "https://icecreator.de/api/",
        debug: false,
        staging: false,
      },
      modalCreateRecept: false,
      modalSearchRecept: false,
      modalPublicFiles: false,
      modalArticle: false,
      modalArticleId: null,
      modalArticleOption: null,
      modalPasswordChange: false,
      password: {
        oldPwd: '',
        pwd: '',
        pwd2: '',
      },
      passwordErrors: {},
      showCanvasRememberList: false,
      CanvasTimer: null,
      mandants: [],
      copyMandant: null,
      showModalReceptBook: false,
    };
  },
  watch:{
    '$auth.check()'(value){
        alert('Hallo');
    },
  },
  methods:{
      logout(){
        this.$store.commit("tabbar/initialiseStore");
        this.$auth.logout();
        this.$router.push({name: 'login'});

      },
      errorHandling(error){
          if(error.response.status === 403){
            this.$toast.error('Ihnen fehlt die Berechtigung für diesen Inhalt');
          }
          else{
            this.$toast.error('Ein unbekannter Fehler ist aufgetreten!<br><b>Error: </b>' + error.response.status.toString() );
          }
      },
      showModalCreateRecept(){
        this.modalCreateRecept = true;
      },
      showModalSearchRecept(){
        this.modalSearchRecept = true;
      },
      showModalPublicRecept(){
        this.modalPublicFiles = true;
      },
      showModalArticle($article, $option){
        this.modalArticleId = $article ? $article : null;
        this.modalArticleOption = $option ? $option : null;
        this.modalArticle = true;
      },
      loadMandants(){
          let url = "mandants/active"
          this.axios.get(url).then(res => {
              //this.mandants = res.data.mandants;
              this.$store.commit('setMandants', res.data.mandants);
              this.copyMandant = null;
          });
      },
      loadAllergenes() {
        this.axios.post("product/allergenes").then(res => {
          this.$store.commit('setAllergenes', res.data.allergenes);
        });
      },
      getBook(){
        this.axios.post("getBook", {recepts: this.$store.state.remember}).then(res => {

        });

        this.axios({
          url: "/getBook",
          method: 'POST',
          responseType: 'blob',
          data: {recepts: this.$store.state.remember},
        }).then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', "Rezeptmappe.pdf");
          document.body.appendChild(fileLink);

          fileLink.click();
        });
      },
      copyToMandant(){
          this.axios.post("recepts/copyToMandants", {mandant: this.copyMandant, recepts: this.$store.state.remember}).then(res => {
              this.$toast.success('Rezepte wurden erfolgreich zum Mandanten <b>' + this.copyMandant.company + '</b> kopiert!');
          }).catch(e => {
            this.$toast.error('Kopiervorgang ist fehlgeschlagen!');
          });
      },
      showRememberList(andHide){
        if(this.CanvasTimer){
          clearTimeout(this.CanvasTimer);
          this.CanvasTimer = null;
        }

        this.showCanvasRememberList = true;

        if(andHide == true){
            this.CanvasTimer = setTimeout(() => {
              this.showCanvasRememberList = false;
              this.CanvasTimer = null;
            }, 1500);
        }
      },
      toDo(){
        this.modalPasswordChange = true;
      },
      setPassword(){
          this.axios.post('auth/changePassword', this.password).then(res => {
            this.passwordErrors = {};
            this.$toast.success('Passwort wurde geändert.');
            this.modalPasswordChange = false;
          }).catch(e => {
              this.passwordErrors = e.response.data.errors;
              this.$toast.error('Ein Fehler ist aufgetreten');
          });
      }
  },
  mounted() {
    this.$store.commit("tabbar/setRouter", this.$router);

    eventBus.$on('custom-event', () => {
      //alert('event is fired');
    })

    window.Pusher = Pusher;


    if (window.icecreator && window.icecreator.config) {
      //this.config = this.extend(this.config, window.icecreator.config);
    }

    /*this.config.globalProperties.$recept = {
        create: this.methods.showModalCreateRecept,
    }*/

    this.$store.commit("tabbar/initialiseStore");

    axios.defaults.baseURL = this.config.url;
    axios.defaults.params = {'lang': "de"};

    let timeout = setInterval(() => {
      if(this.$auth.ready()){
        clearInterval(timeout);
      }

      if(this.$auth.check()){
        this.loadMandants();
        this.loadAllergenes();
        window.Echo = new Echo({
          broadcaster: 'pusher',
          key: "ae9bf0b62d4eb3ce03e0",
          cluster: "eu",
          forceTLS: true,
          authorizer: (channel, options) => {
            return {
              authorize: (socketId, callback) => {
                axios.post('broadcasting/auth', {
                  socket_id: socketId,
                  channel_name: channel.name
                })
                    .then(response => {
                      callback(null, response.data);
                    })
                    .catch(error => {
                      callback(error);
                    });
              }
            };
          },
        });

        window.Echo.channel('updates').listen(".message.send", (e) => {
          this.$toast.info(e.message);
        });

        window.Echo.private('updates').listen(".message.send", (e) => {
          console.log(e);
          this.$toast.error(e.message);
        }).listenToAll((event, data) => {
          // do what you need to do based on the event name and data
          console.log(event, data);
        });
      }
    }, 300);

  }
}
</script>

